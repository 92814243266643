.navbar {

  &.navbar-inverse {

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 10;

    &.navbar-menu {
      z-index: -5;

      .navbar-brand {
        opacity: 1;
      }
    }

    .container {
      width: 100vw;
      padding: 0;
      margin: 0;

      @media (max-width: 850px) {
        width: 90vw;
      }
    }

    .navbar-collapse {
      display: table !important;
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;

      opacity: 0;

      transition: all 800ms ease-in;

      background: linear-gradient(45deg, $color-primary 0, #4eb864 100%);
      text-align: center;

      &.open {
        opacity: 1;
      }

      .navbar-nav {
        display: table-cell;
        vertical-align: middle;

        text-align: center;
        flex-direction: inherit;

        width: auto;
        height: auto;

        .nav-item {
          display: block;
          float: none;

          padding: 0 1rem;
          width: 100%;
          max-width: 700px;
          margin: auto;

          opacity: 0;

          .btn-figure {
            border: 0px;

            margin-left: auto;
            margin-right: auto;
            width: 100%;

            margin-bottom: 1rem;

            font-size: 1.5rem;

            span:nth-child(1) {
              background: transparent;
              color: #fff;
              font-weight: 300;
            }

            span:nth-child(2) {
              background-color: #fff;
              color: $color-primary;
              font-weight: 500;

              border-radius: 0;
              border-top-left-radius: 1rem;
              border-bottom-left-radius: 1rem;
              border-top-right-radius: 1rem;
            }
          }

          &:hover, &.active {
            .btn-figure {
              span:nth-child(1) {
                transform: translate3d(0, -100%, 0);
              }

              span:nth-child(2) {
                transform: translate3d(0, 0, 0);
              }
            }
          }
        }
      }
    }

    &.show {
      .navbar-brand {
        opacity: 1;
      }
    }

    &.dark, &.black {

      .navbar-toggler-xl {
        color: $color-primary;

        background-color: #292b2c;

        &:after {
          background-color: $color-primary;
        }

        > span {
          border-color: $color-primary;
        }

        &:hover {
          color: #fff;
          background-color: #292b2c;

          > span {
            border-color: #fff;
          }

          &:after {
            transform: scale(1);
          }
        }
      }

      .navbar-brand {
        background-color: $color-primary;
        opacity: 1;
      }
    }

    .navbar-brand {
      width: 114.8px;
      height: 114.8px;

      transition: all 200ms;
      opacity: 0;

      margin: 0;
      display: block;
      text-align: center;
      padding-top: 1.3rem;
      z-index: 3;
      position: relative;

      border-bottom-left-radius: 0.5rem;
    }

    .navbar-toggler-xl {
      border: 0;
      background: transparent;
      padding: 1rem;
      padding-top: 1.3rem;

      font-family: $font-family-monospace;
      font-weight: 100;
      font-size: 2rem;

      word-break: break-all;
      width: 114.8px;
      height: 114.8px;
      text-align: center;
      line-height: 2rem;
      color: #fff;
      transition: all 200ms;

      overflow: hidden;

      z-index: 3;

      border-bottom-right-radius: 0;

      position: absolute;
      right: auto;
      left: 0;

      margin-top: -57.4px;

      top: 50vh;

      > span {

        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid #fff;
        transition: all 400ms;

        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;

        span {

          position: relative;
          top: -27px;

          &:before {
            content: "\A";
            white-space: pre;
          }
        }
      }

      &:after {
        content: ' ';

        display: block;
        width: 100%;
        height: 100%;

        border-bottom-right-radius: 0;

        position: absolute;
        left: 0;
        top: 0;

        z-index: -1;
        background-color: #292b2c;

        transform-origin: 0% 50%;
        transform: scale(0);
        transition: all 700ms ease-in;

        -webkit-transition-timing-function: cubic-bezier(1.000, 0.005, 0.000, 1); /* older webkit */
        -webkit-transition-timing-function: cubic-bezier(1.000, 0.005, 0.000, 1.650);
        -moz-transition-timing-function: cubic-bezier(1.000, 0.005, 0.000, 1.650);
        -o-transition-timing-function: cubic-bezier(1.000, 0.005, 0.000, 1.650);
        transition-timing-function: cubic-bezier(1.000, 0.005, 0.000, 1.650); /* custom */

      }

      &:hover {
        color: $color-primary;

        background-color: #292b2c;

        &:after {
          transform: scale(1);
        }

        > span {
          border-color: $color-primary;
        }
      }

    }

  }
}