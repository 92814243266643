#animateSun {
  animation: animateSun 1.5s ease;
  display: block;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
}

@keyframes animateSun {
  0% {
    opacity: 0;
    transform: translate3d(0, 500px, 0);
  }
  to {
    transform: none;
    opacity: 1;
  }
}

#redbeed-logo-svg {
  animation: redbeed-logo-svg 1.5s ease;
  animation-delay: 2.1s;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes redbeed-logo-svg {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
