section {
  &.project-title {
    height: 100vh;

    width: 80vw;
    margin: auto;

    @media (max-width: 850px) {
      width: 90vw;
    }

    overflow: hidden;

    position: relative;

    display: table;

    .title-banner {
      position: absolute;
      height: 100vh;
      width: 100%;

      top: 0;
      left: 0;

      line-height: 100vh;
      text-align: center;

      background-color: #000;

      z-index: -1;

      &.schockmann {
        background-image: url('/images/projects/schockmann/cover.jpg');
        background-size: cover;
      }

      &.renasdreams {
        background-color: transparent;
        background: linear-gradient(45deg, #272a30 0, #841c1d 57%, #841c1d 100%);

        img {
          width: 120%;
          height: auto;

          position: relative;
          top: -30%;
          left: -5%;
        }

        &:after {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          //background: linear-gradient(45deg, #272a30 0, #841c1d 57%, #841c1d 100%);
          background-color: #272a30;
          opacity: 0.8;

          content: ' ';

        }
      }

      img {
        vertical-align: middle;
      }
    }

    .title-infos {
      color: #ffffff;
      display: table-cell;
      vertical-align: middle;

      .infos {
        max-width: 980px;
        width: 100%;
        padding: 1rem;
        display: block;
        margin: auto;

        h1 {
          font-size: 5rem;
          font-weight: 300;


          @media (max-width: 1250px) {
            font-size: 4.5rem;
          }

          @media (max-width: 1120px) {
            font-size: 4rem;
          }

          @media (max-width: 985px) {
            font-size: 3.5rem;
          }

          @media (max-width: 780px) {
            font-size: 3rem;
          }

          @media (max-width: 645px) {
            font-size: 2.7rem;
          }

          @media (max-width: 580px) {
            font-size: 2.2rem;
          }

        }

        span {
          font-size: 1.5rem;
          font-weight: 100;

          display: block;
          width: 80%;

          @media (max-width: 1250px) {
            font-size: 1.5rem;
          }

          @media (max-width: 1120px) {
            font-size: 1.4rem;
          }

          @media (max-width: 985px) {
            font-size: 1.3rem;
          }

          @media (max-width: 780px) {
            font-size: 1.2rem;
          }

          @media (max-width: 645px) {
            font-size: 1.1rem;
          }

          @media (max-width: 580px) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}