.carousel {
  &.slide {
    .carousel-item {
      height: 700px;
      video {
        object-fit: cover;
        object-position: 100% 0%;
      }

      &:after {
        content: ' ';
        background-color: #14190B;
        //background-color: #88B04B;
        width: 100%;
        height: 100%;

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        opacity: 0.5;
      }

      .carousel-caption {

        bottom: 200px;

        h2 {
          //text-transform: uppercase;
          font-size: 3.5rem;
          font-weight: 900;
        }

        p {
          font-size: 1.5rem;

          .btn {
            &.btn-primary {
              font-size: 1.2rem;
            }
          }

        }

        span {
          color: $color-primary;
        }

      }
    }
  }
}