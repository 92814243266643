section {
  &.agency-header {

    position: relative;

    height: 50vh;

    width: 80vw;
    margin: auto;

    @media (max-width: 850px) {
      width: 90vw;
    }

    .circle_head {
      background-image: url('/images/thatsme/cover_1.jpg');
      background-size: cover;

      width: 40%;

      position: absolute;
      top: -20%;
      right: 0;

      border-radius: 50%;

      img {
        width: 100%;
        height: auto;

        border-radius: 50%;
      }
    }


    h1 {
      width: 60%;
      text-align: center;

      margin: 0;
      padding: 0;

      padding-top: 10vh;
      font-size: 6rem;

      font-weight: 300;

      @media (max-width: 1250px) {
        font-size: 5.5rem;
      }

      @media (max-width: 1120px) {
        font-size: 5rem;
      }

      @media (max-width: 985px) {
        font-size: 4.5rem;
      }

      @media (max-width: 780px) {
        font-size: 4rem;
      }

      @media (max-width: 645px) {
        font-size: 3.7rem;
      }

      @media (max-width: 580px) {
        font-size: 3.2rem;
      }

      small {

        &:before {
          content: "\A";
          white-space: pre;
        }

        font-size: 45%;
        color: #969696;
        font-weight: 300;

        position: relative;
        top: -51px;

      }
    }

  }
}