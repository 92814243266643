.project-card {

  width: 80vw;
  margin: auto;

  @media (max-width: 850px) {
    width: 90vw;
  }

  .card-infos {
    padding: 5.5rem 1rem;
    color: #ffffff;
    border-bottom-left-radius: 0.5rem;
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: rgba(6, 21, 6, 0.51);

      content: ' ';
    }

    &.green {
      background: linear-gradient(45deg, $color-primary 0, #4eb864 100%);

      .btn-figure {
        span:nth-child(2) {
          color: $color-primary;
        }
      }
    }

    &.blue {
      background: linear-gradient(45deg, #6ab1d7 0, #33d9de 100%);

      .btn-figure {
        span:nth-child(2) {
          color: #6ab1d7;
        }
      }

    }

    &.red {
      background: linear-gradient(45deg, #ff4f58 0, #ff4f58 57%, #fd7048 100%);

      .btn-figure {
        span:nth-child(2) {
          color: #ff4f58;
        }
      }

    }

    h2, p {
      max-width: 980px;
      width: 100%;
      display: block;
      margin: auto;

      position: relative;
      z-index: 1;

      text-align: center;
    }

    h2 {
      font-size: 3rem;
      font-weight: 300;
      position: relative;

      padding: 0 1rem;
    }

    p {
      font-weight: 100;
      font-size: 1.5rem;
      padding: 0 1rem;
    }

    .btn-figure {
      border: 2px solid #fff;
      border-radius: 0;

      display: block;
      margin-top: 1rem;
      margin-left: auto;
      margin-right: auto;

      max-width: 490px;
      width: 100%;

      position: relative;
      z-index: 1;

      span:nth-child(1) {
        background: transparent;
        color: #fff;
        font-weight: 300;
      }

      span:nth-child(2) {
        background-color: #fff;
        color: #fff;
        font-weight: 500;
      }

    }
  }
}

.project-card-images {
  display: flex;

  width: 80vw;
  margin: auto;

  @media (max-width: 850px) {
    width: 90vw;
  }

  .card-images-big, .card-images-small {

    -webkit-box-flex: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  .card-images-big {

    -webkit-flex: 0 0 66.6666666667%;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;

  }

  .card-images-small {
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

}