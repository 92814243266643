footer {
  background: linear-gradient(45deg, $color-primary 0, #4eb864 100%);
  width: 80vw;
  min-height: 50vh;
  margin-left: 10vw;
  margin-top: 20vh;
  position: relative;

  .title {
    width: 100%;
    text-align: center;
    font-size: 7rem;
    font-weight: 300;

    position: relative;
    top: -80px;

    @media all and (max-width: 650px) {
      font-size: 6rem;
    }

    @media all and (max-width: 450px) {
      font-size: 3rem;
    }

    @media all and (max-width: 350px) {
      font-size: 2.5rem;
    }
  }

  span {
    &.subtitle {
      font-size: 1.5rem;

      @media all and (max-width: 450px) {
        font-size: 1.1rem;
      }

      @media all and (max-width: 350px) {
        font-size: 0.9rem;
      }

      display: block;
      width: 100%;
      text-align: center;

      position: relative;
      top: -80px;
    }

  }

  .footer-text {
    padding: 0 2rem;

    .quote {
      width: 100%;
      display: block;
      text-align: center;
      color: #fff;
      font-size: 1.5rem;
      font-weight: 100;
      font-style: italic;
      margin-bottom: 3.5rem;
    }

    .col {
      .btn-figure {
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;

        margin-left: auto;
        margin-right: auto;
        width: 100%;
        min-width: 100%;

        span:nth-child(1) {
          background: transparent;
          color: #fff;
          font-weight: 100;
        }

        span:nth-child(2) {
          background-color: #fff;
          color: $color-primary;
          font-weight: 500;
          transform: translate3d(100%, 0, 0);
        }

        &:hover {
          span:nth-child(1) {
            transform: translate3d(-100%, 0, 0);
          }

          span:nth-child(2) {
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }
}