section {
  &.home-thatsme {

    position: relative;

    .thatsme-background {
      width: 30vw;
      position: absolute;
      left: 10vw;
      height: 500px;
      background: linear-gradient(45deg, #6ab1d7 0, #33d9de 100%);

      span {

        position: absolute;
        display: block;
        height: 90%;
        width: 1px;
        left: -30px;
        top: -10px;

        img {
          height: 100%;
          width: auto;

          @media all and (max-width: 900px) {
            height: 90%;
          }

        }
      }

      .circle {
        background: transparent;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: absolute;
        border: 2px solid #33d9de;

        top: 0;
        left: 0;

        &:nth-child(2) {
          width: 120px;
          height: 120px;

          left: auto;
          right: 20%;
          top: 40%;
        }

        &:nth-child(3) {
          width: 60px;
          height: 60px;

          left: -10%;
          bottom: 10%;
          top: auto;
        }

        &:nth-child(4) {
          width: 80px;
          height: 80px;

          left: auto;
          right: 45%;
          top: 10%;
        }
      }
    }

    .thatsme-text {
      width: 60vw;
      height: 500px;
      text-align: center;
      margin-left: 40vw;

      display: flex;
      justify-content: center;
      flex-direction: column;

      h2 {
        font-size: 4rem;
        font-weight: 300;
        color: #33d9de;
      }

      p {
        font-weight: 300;
        line-height: 2;
        font-size: 16px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
      }

      .btn-figure {
        border: 2px solid #33d9de;

        margin-left: auto;
        margin-right: auto;
        width: 60%;
        min-width: 211px;

        span:nth-child(1) {
          background: #fff;
          color: #33d9de;
          font-weight: 300;
        }

        span:nth-child(2) {
          background-color: #33d9de;
          color: #fff;
          font-weight: 500;
        }
      }
    }

    @media (max-width: 780px) {
      .thatsme-text {
        background-color: #fff;
        z-index: 10;
        position: absolute !important;
        width: 60vw;
        top: 2.5rem;
        right: 0;

        height: auto;
        padding: 1.5rem;

        h2 {
          font-size: 2rem;
          text-align: justify;
          width: 80%;
          margin-left: auto;
          margin-right: auto;
        }

        p {
          line-height: 1.6;
          font-size: 14px;
          width: 80%;
          text-align: justify;
        }

        .btn-figure {
          width: 80%;
        }
      }

      .thatsme-background {
        width: 60vw !important;
        position: relative !important;
        height: 400px;
      }
    }

    @media (max-width: 450px) {
      .thatsme-text {
        .btn-figure {
          width: 100%;
          min-width: auto;
        }
      }
    }

  }
}