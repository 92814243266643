
// Fonts
@import url(https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,900);
@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro:300);

// Variables
@import "variables";

@import "body";
@import "btn";
@import "layout/main";
@import "home/main";

@import "projects/main";
@import "agency/main";