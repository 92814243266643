.logo-header {

  min-height: 100vh;
  position: relative;

  .logo-header-background {
    background: linear-gradient(45deg,$color-primary 0,#4eb864 100%);
    width: 80vw;
    height: 100vh;

    min-height: 500px;

    top: -50vh;

    position: absolute;
    left: 10vw;

    z-index: -1;
    
    @media (max-width: 850px) {
      width: 90vw;
      left: 5vw;
    }
    
  }

  .logo-header-logo {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 7vh;
    padding-bottom: 5vh;

    @media all and (max-width: 701px) {
      width: 100%;
    }

    img {
      width: 50%;
      height: auto;
      margin: auto;
      display: block;

    }

    h1 {
      width: 100%;
      text-align: center;
      font-size: 10rem;
      font-weight: 300;
      margin-bottom: 0rem;

      @media all and (max-width: 800px) {
        font-size: 8rem;
      }

      @media all and (max-width: 650px) {
        font-size: 7rem;
      }

      @media all and (max-width: 450px) {
        font-size: 5rem;
      }

      @media all and (max-width: 350px) {
        font-size: 4.5rem;
      }
    }

    span {
      font-size: 2rem;

      @media all and (max-width: 450px) {
        font-size: 1.5rem;
      }

      @media all and (max-width: 350px) {
        font-size: 1.3rem;
      }

      display: inline-block;
      width: 100%;
      text-align: center;

    }
  }

  .quote {
    font-family: $font-family-handwritten;
    font-size: 2rem;
    display: block;
    width: 80%;
    text-align: center;
    color: $color-primary;

    position: absolute;
    left: 10%;
    bottom: 25px;


    .name {
      font-family: $font-family-sans-serif;
      font-size: 1.5rem;
    }


    @media all and (min-width: 1070px) {
      width: 60%;
      left: 20%;
    }

    @media all and (max-width: 650px) {
      font-size: 1rem;


      .name {
        font-size: 0.9rem;
      }
    }

    @media all and (max-width: 350px) {
      font-size: 0.9rem;

      .name {
        font-size: 0.8rem;
      }
    }

    .triangle {
      /* this CS forms the triangles */

      position: relative;
      display: block;
      margin: auto;
      margin-bottom: 1rem;
      width: 1px;
      height: 1px;

      &:after, &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
      }


      /* this border color controlls the color of the triangle (what looks like the fill of the triangle) */
      &:after {
        left: 3px;
        border-color: #fff transparent transparent transparent;
        border-width: 10px;
      }

      /* this border color controlls the outside, thin border */
      &:before {
        top: 0;
        border-color: $color-primary transparent transparent transparent;
        border-width: 13px;
      }
    }
  }
}