section {
  &.agency-city {

    width: 80vw;
    margin: auto;

    position: relative;

    @media (max-width: 850px) {
      width: 90vw;
    }

    img {

      width: 30%;
      height: auto;
      margin-right: 2rem;
    }

    .city-info {
      width: 60%;
      display: inline-block;

      h2 {
        font-size: 5rem;
        font-weight: 300;
        color: #ff4f58;

        @media (max-width: 1250px) {
          font-size: 4.5rem;
        }

        @media (max-width: 1120px) {
          font-size: 4rem;
        }

        @media (max-width: 985px) {
          font-size: 3.5rem;
        }

        @media (max-width: 780px) {
          font-size: 3rem;
        }

        @media (max-width: 645px) {
          font-size: 2.7rem;
        }

        @media (max-width: 580px) {
          font-size: 2.2rem;
        }
      }
    }



  }
}