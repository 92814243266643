body {
  font-family: $font-family-sans-serif !important;
}

a {
  color: $color-primary;
  transition: linear 0.5s;
  &:hover {
    color: $color-primary-dark;
  }
}