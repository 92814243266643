section {
  &.highlights {
    padding-top: 10vh;
    padding-bottom: 10vh;
    background: linear-gradient(45deg, #353535 0, #000000 100%);

    margin-bottom: 10vh;
    overflow: hidden;
    position: relative;

    z-index: 3;

    h2 {
      color: #fff;
      position: relative;
      width: auto;
      display: inline-block;
      margin-bottom: 3rem;

      font-size: 5rem;
      font-weight: 300;

      @media (max-width: 1250px) {
        font-size: 4.5rem;
      }

      @media (max-width: 1120px) {
        font-size: 4rem;
      }

      @media (max-width: 985px) {
        font-size: 3.5rem;
      }

      @media (max-width: 780px) {
        font-size: 3rem;
      }

      @media (max-width: 645px) {
        font-size: 2.7rem;
      }

      @media (max-width: 580px) {
        font-size: 2.2rem;
      }

      &:after {

        content: ' ';
        position: absolute;
        width: 150%;
        height: 2px;

        background-color: #fff;
        left: -50%;
        bottom: -5px;
      }
    }

    a {
      color: #fff;
      &:hover {
        text-decoration: none;
        color: $color-primary-dark;
      }
    }

    .highlights-lines {

      .line {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        stroke-width: 1px;
        stroke-dasharray: 4;

        z-index: -2;

        &:nth-child(1) {
          stroke: $color-primary;
          transform: rotate(-32deg) translate3d(-35rem, -20rem, 0);
        }

        &:nth-child(2) {
          stroke: #ff4f58;
          transform: rotate(-45deg) translate3d(-35rem, -20rem, 0);
        }

        &:nth-child(3) {
          stroke: #ff4f58;
          transform: rotate(45deg) translate3d(72rem, -30rem, 0);
        }
      }

    }

    .container {
      .row {

        @media all and (max-width: 800px) {
          //display: block;
          > div {
            width: 50vw !important;
            flex-basis: inherit;
            flex-grow: inherit;
            margin-bottom: 5rem;
          }
        }

        @media all and (max-width: 600px) {
          display: block;
          > div {
            width: 80vw !important;
            margin-left: 10vw;
            margin-bottom: 10rem;
            //background-color: #353535;
          }
        }

        > div {
          .highlight-entry {
            text-align: center;
            color: #fff;
            position: relative;
            padding: 0;

            &:hover {
              a {
                &.img {
                  &:nth-child(1) {
                    img {
                      opacity: 0;
                    }
                  }

                  &:nth-child(2) {
                    img {
                      opacity: 1;
                    }
                  }
                }
              }
            }

            a {
              &.img {
                img {

                  width: 100%;
                  height: auto;
                  display: block;
                  position: relative;
                  z-index: -1;

                  transition: all 1s;

                  margin-bottom: 1.5em;
                }

                &:nth-child(2) {
                  img {
                    opacity: 0;

                    position: absolute;
                    left: 0;
                    top: 0;
                  }
                }
              }
            }

            .infos {
              z-index: 100;
              font-weight: 100;
              font-size: 2rem;

              position: absolute;
              top: 1.5rem;
              left: 1.5rem;
              right: 1.5rem;
              bottom: 1rem;

              opacity: 0;


              transition: all 0.3s linear;

              .center {

                background: #fff;
                height: 100%;
                width: 100%;

                display: table;

                h3 {
                  display: table-cell;
                  position: relative;
                  vertical-align: middle;

                  height: 100%;
                  width: 100%;

                  color: #353535;

                  a {
                    color: inherit;
                  }

                  small {
                    font-weight: 100;
                    display: block;
                    font-size: 1.2rem;

                    margin-top: 0.25rem;
                  }

                }

              }
            }

            &:hover {
              .infos { opacity: 1; }

              a {
                .btn-figure {
                  span:nth-child(1) {
                    transform: translate3d(0, -100%, 0);
                  }

                  span:nth-child(2) {
                    transform: translate3d(0, 0, 0);
                  }
                }
              }
            }

            .btn-figure {
              border: 1px solid #fff;

              margin-left: auto;
              margin-right: auto;
              width: 50%;
              min-width: 193px;

              margin-top: 0.5rem;

              &.btn-lg {
                border-radius: 0;
                display: block;
                font-size: 1.5rem;
                margin-top: 4rem;

                border-width: 0;
                border-bottom-width: 2px;

                max-width: 350px;

                &:hover {
                  span:nth-child(2) {
                    border-bottom-left-radius: 0;
                  }
                }

                i {
                  color: #ff4f58;
                }
              }

              span {
                padding: 0.5rem;
              }

              span:nth-child(1) {
                background: transparent;
                color: #fff;
                font-weight: 100;
              }

              span:nth-child(2) {
                background-color: #fff;
                color: #353535;
                font-weight: 500;
              }
            }

          }
        }
      }
    }
  }
}