.btn {
  &.btn-primary {
    background-color: $color-primary;
    border-color: $color-primary;

    &:hover {
      background-color: $color-primary-dark;
      border-color: $color-primary-dark;
    }
  }

  &.btn-figure {
    width: 28rem;
    overflow: hidden !important;
    position: relative;

    margin: 0;
    padding: 0;


    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0;

    span {
      position: relative;
      height: 100%;
      width: 100%;
      display: block;
      padding: 1rem;
      transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);


      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0;

    }

    span:nth-child(1) {
      background-color: transparent;
      background-color: #000;
      transform: translate3d(0, 0, 0);
    }

    span:nth-child(2) {
      position: absolute;
      left: 0;
      top: 0;
      background-color: red;
      transform: translate3d(0, 100%, 0);
    }

    &:hover {
      span:nth-child(1) {
        transform: translate3d(0, -100%, 0);
      }

      span:nth-child(2) {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}