section {
  &.section-steps {
    background-color: #fff;
    position: relative;

    .row {
      >.col-xl-6 {
        padding: 1rem;
      }
    }

    &:before {
      width: 50%;
      height: 100%;
      content: ' ';

      position: absolute;
      top: 0;
      left: 50%;
      background-color: $color-primary;
    }
  }
}