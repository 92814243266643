.rellax {
  -webkit-transition: 500ms linear;
  -moz-transition: 500ms linear;
  -o-transition: 500ms linear;

  -webkit-animation-delay: 0.05s; /* Safari 4.0 - 8.0 */
  animation-delay: 0.05s;

  //-webkit-transition-timing-function: cubic-bezier(1.000, 0.030, 0.580, 1.000);
  //-moz-transition-timing-function: cubic-bezier(1.000, 0.030, 0.580, 1.000);
  //-o-transition-timing-function: cubic-bezier(1.000, 0.030, 0.580, 1.000);
  //transition-timing-function: cubic-bezier(1.000, 0.030, 0.580, 1.000); /* custom */
}