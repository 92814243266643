section {
  &.into {
    width: 100%;
    position: relative;

    margin-bottom: 4rem;

    .info-text, .into-background {
      position: absolute;
      width: 50vw;
      height: 600px;
      right: 0;
      bottom: 0;
      top: 0;
    }

    @media (max-width: 780px) {
      .info-text, .into-background {
        width: 80vw;
        position: relative;
      }

      .into-background {
        margin-left: 50vw;
        width: 50vw;
        min-height: 40vh !important;
      }
    }

    @media (max-width: 780px) {
      .into-background {
        margin-right: 0;
        margin-left: auto;
        width: 70vw;
        height: 400px;
      }
    }

    @media (max-width: 450px) {
      .into-background {
        height: 300px;
      }
    }

    .into-background {

      background: linear-gradient(45deg, #ff4f58 0, #ff4f58 57%, #fd7048 100%);
      //min-height: 80vh;

      div {

        position: relative;
        width: 100%;
        height: 100%;

        img {
          width: auto;
          height: 90%;
          position: absolute;

          left: -8%;
          top: 1vh;
          transform-origin: 50% 50%;
          transition: all 1s ease;

          @media (max-width: 1200px) and (min-width: 650px) {
            height: 90% !important;
            width: auto !important;
          }

          @media all and (max-width: 450px) {
            width: 80% !important;
          }
        }

      }

    }

    .info-text {

      position: relative;
      left: 0;
      right: auto;

      padding: 1.5em;
      text-align: center;

      display: flex;
      justify-content: center;
      flex-direction: column;

      @media (max-width: 780px) {
        margin-right: 0;
        margin-left: auto;
        height: 400px;
        width: 100%;
      }

      @media (max-width: 450px) {
        height: 350px;
      }

      h2 {
        font-size: 4rem;
        font-weight: 300;
        color: #ff4f58;
        position: relative;
        width: auto;
        margin-left: auto;
        margin-right: auto;

        @media all and (max-width: 900px) {
          font-size: 2rem;
        }

        .shapes {
          position: relative;
          span {
            display: block;
            width: 10px;
            height: 10px;

            border: 1px solid;
            border-color: #ff4f58;

            border-radius: 50%;

            position: absolute;
            top: -0px;
            left: -10px;
          }

          span:nth-child(1) {
            animation: info-text-span-1 12s infinite ease;

            @keyframes info-text-span-1 {
              0% {
                top: -0px;
                left: -10px;
              }
              50% {
                top: -3px;
                left: -5px;
              }
              100% {
                top: -0px;
                left: -10px;
              }
            }
          }

          span:nth-child(2) {
            width: 12px;
            height: 12px;

            transform-origin: 50% 50%;

            top: -10px;
            left: 10px;

            animation: info-text-span-2 15s infinite ease, info-text-span-2-scale 10s infinite ease;

            @keyframes info-text-span-2 {
              0% {
                top: -10px;
                left: 10px;
              }
              50% {
                top: -8px;
                left: 14px;
              }
              100% {
                top: -10px;
                left: 10px;
              }
            }

            @keyframes info-text-span-scale {
              0% {
                transform: scale(1);
              }
              50% {
                transform: scale(0.8);
              }
              100% {
                transform: scale(1);
              }
            }

          }

          span:nth-child(3) {
            width: 9px;
            height: 9px;

            top: -12px;
            left: -5px;

            animation: info-text-span-3 12s infinite ease;

            @keyframes info-text-span-3 {
              0% {
                top: -12px;
                left: -5px;
              }
              50% {
                top: -10px;
                left: -3px;
              }
              100% {
                top: -12px;
                left: -5px;
              }
            }

          }
        }
      }

      p {
        font-weight: 300;
        line-height: 2;
        font-size: 16px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;

        @media all and (max-width: 900px) {
          font-size: 14px;
          line-height: 1.6;
        }
      }

      .btn-figure {
        border: 2px solid #ff4f58;

        margin-left: auto;
        margin-right: auto;
        width: 60%;
        min-width: 190px;

        span:nth-child(1) {
          background: #fff;
          color: #ff4f58;
          font-weight: 300;
        }

        span:nth-child(2) {
          background-color: #ff4f58;
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }
}