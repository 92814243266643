section {
  &.highlights {
    &.list {
      width: 100vw;

      margin: auto;

      @media (max-width: 850px) {
        width: 90vw;
      }

      p {
        color: #fff;
        text-align: center;
        margin: auto;
        margin-bottom: 3.5rem;
        font-size: 1.5rem;
        font-weight: 100;

        width: 80%;

        span {
          font-weight: 300;
          color: #ff4f58;
        }

        @media (max-width: 1250px) {
          font-size: 1.5rem;
        }

        @media (max-width: 1120px) {
          font-size: 1.4rem;
        }

        @media (max-width: 985px) {
          font-size: 1.3rem;
        }

        @media (max-width: 780px) {
          font-size: 1.2rem;
        }

        @media (max-width: 645px) {
          font-size: 1.1rem;
        }

        @media (max-width: 580px) {
          font-size: 1rem;
        }
      }

      h2 {
        width: 100%;
        display: block;
        text-align: center;

        position: relative;
        left: auto;
        top: auto;
        margin-left: 0;
        font-weight: 300;

        margin-bottom: 1.5rem;

        i {
          color: #ff4f58;
        }

        &:after {
          display: none;
        }
      }

      .container-fluid {
        .row {
          .col {

            width: 33.333333333333% !important;
            flex-basis: inherit;
            flex-grow: inherit;
            margin-bottom: 5rem;

            @media all and (max-width: 1100px) {
              width: 50% !important;
            }

            @media all and (max-width: 600px) {
              width: 80% !important;
              margin-left: 10%;
            }

            @media all and (max-width: 500px) {
              width: 90% !important;
              margin-left: 5%;
            }
          }
        }
      }
    }
  }
}