.background-shape {
  position: absolute;
  top: 0;
  left: 0;

  transform-origin: 50% 50%;
  transition: all 1.5s linear;

  &.hexa {
    width: 66px;
    height: 66px;

    right: 10%;
    bottom: 20%;
    top: auto;
    left: auto;

    polygon {
      stroke: $color-primary;
      stroke-width: 0.6;
    }


    animation: background-shape-hexa 24s infinite ease, background-shape-hexa-rotate 60s infinite ease;

    @keyframes background-shape-hexa {
      0%   {
        right: 10%;
        bottom: 20%;
      }
      50% {
        right: 9%;
        bottom: 6%;
      }
      100%   {
        right: 10%;
        bottom: 20%;
      }
    }

    @keyframes background-shape-hexa-rotate {
      0%   {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(360deg);
      }
      100%   {
        transform: rotate(0deg);
      }
    }
  }

  &.triangle {

    width: 112px;
    height: 112px;

    right: 6%;
    top: 15%;
    left: auto;

    animation: background-shape-triangle 20s infinite ease, background-shape-triangle-rotate 40s infinite ease;

    polygon {
      stroke: $color-primary;
      stroke-width: 0.3;
    }

    @keyframes background-shape-triangle {
      0%   {
        top: 15%;
      }
      50% {
        top: 28%;
      }
      100%   {
        top: 15%;
      }
    }

    @keyframes background-shape-triangle-rotate {
      0%   {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(180deg);
      }
      100%   {
        transform: rotate(0deg);
      }
    }
  }

  &.circle {
    width: 112px;
    height: 112px;

    border: 1px solid;
    border-color: $color-primary;

    border-radius: 50%;

    left: 3%;
    top: 50%;

    animation: background-shape-circle 15s infinite ease, border-color 2s linear;

    @keyframes background-shape-circle {
      0%   {
        left: 2%;
        top: 25%;
      }
      50% {
        left: 3%;
        top: 30%;
      }
      100%   {
        left: 2%;
        top: 25%;
      }
    }
  }

  &.circle-small {
    width: 32px;
    height: 32px;

    border: 1px solid;
    border-color: $color-primary;

    border-radius: 50%;

    left: 45%;
    top: auto;
    bottom: 30%;

    animation: background-shape-circle-small 28s infinite ease, background-shape-circle-small-bottom 32s infinite ease;

    @keyframes background-shape-circle-small {
      0%   {
        left: 45%;
      }
      50% {
        left: 46%;
      }
      100%   {
        left: 45%;
      }
    }

    @keyframes background-shape-circle-small-bottom {
      0%   {
        bottom: 30%;
      }
      50% {
        bottom: 16%;
      }
      100%   {
        bottom: 30%;
      }
    }
  }

  &.cube-big {
    width: 80px;
    height: 80px;

    border: 1px solid;
    border-color: $color-primary;

    left: 3%;
    top: 50%;

    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    animation: background-shape-cube-big 10s infinite ease, background-shape-cube-big-rotate 20s infinite ease, border-color 2s linear;

    @keyframes background-shape-cube-big {
      0%   {
        left: 39%;
        top: 55%;
      }
      50% {
        left: 40%;
        top: 60%;
      }
      100%   {
        left: 39%;
        top: 55%;
      }
    }

    @keyframes background-shape-cube-big-rotate {
      0%   {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(180deg);
      }
      100%   {
        transform: rotate(360deg);
      }
    }
  }

  &.cube-small {
    width: 12px;
    height: 12px;

    border: 1px solid;
    border-color: $color-primary;

    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    left: 13%;
    top: auto;
    bottom: 10%;

    animation: background-shape-cube-small 10s infinite ease, background-shape-cube-small-rotate 30s infinite ease;

    @keyframes background-shape-cube-small {
      0%   {
        left: 13%;
        bottom: 10%;
      }
      50% {
        left: 12%;
        bottom: 7%;
      }
      100%   {
        left: 13%;
        bottom: 10%;
      }
    }

    @keyframes background-shape-cube-small-rotate {
      0%   {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(180deg);
      }
      80%   {
        transform: rotate(250deg);
      }
      100%   {
        transform: rotate(360deg);
      }
    }
  }
}

.background-shape-moving-one, .background-shape-moving-two {

  z-index: -3;

  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  /* TEST */
}
